import './App.css';
import Calculation from './components/Calculation';
import React, {useState } from "react";

function App() {

  const [rasbery, setRasbery] = useState(0);
  
  return (
    <div className="App">
      <h1>Калькулятор v 10.09.2024</h1>
      <p>Введите количество малины</p>
      <input type="number" value={rasbery} onChange={(e) => setRasbery(e.target.value)}></input>      
      <Calculation>{rasbery}</Calculation>      
    </div>
  );
}

export default App;
