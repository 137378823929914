import React, { useEffect, useState } from "react";

const cofWheat = 48.0;
const cofCorn = 24;
const cofTomato = 12;
const cofEggplant = 6.0;
const cofMelon = 4.0;
const CofRasbery = 3.0;

const Calculation = (props) => {
  const [lvlHouse, setLvlHouse] = useState(5);
  const [cup, setCup] = useState(0);
  const [haveWeat, setHaveWeat] = useState(0);
  const [haveCorn, setHaveCorn] = useState(0);
  const [HaveTomato, setHaveTomato] = useState(0);
  const [HaveEggplant, setHaveEggplant] = useState(0);
  const [HaveMelon, setHaveMelon] = useState(0);


  const rasbery = props.children;

  const weat =Math.round((rasbery / CofRasbery) * cofWheat)
  const corn=Math.round((rasbery / CofRasbery) * cofCorn)
  const tomato = Math.round((rasbery / CofRasbery) * cofTomato)
  const eggplant=Math.round((rasbery / CofRasbery) * cofEggplant)
  const melon = Math.round((rasbery / CofRasbery) * cofMelon)

  const handleClickCalc = () =>
  {
    setCup(Math.round(rasbery/(12*lvlHouse)*140))
  }
  useEffect(handleClickCalc,[lvlHouse,rasbery])

  return (
    <>
      <div>
        Пшеницы = {weat} (
        {Math.round(0.95*weat)}-
        {Math.round(1.05*weat)})
      </div>
      <div>
        Кукурузы = {corn} (
        {Math.round(0.95*corn)}-
        {Math.round(1.05*corn)})
      </div>
      <div>
        Помидоры = {tomato} (
        {Math.round(0.95*tomato)}-
        {Math.round(1.05*tomato)})
      </div>
      <div>
        Баклажаны = {eggplant} (
        {Math.round(0.95*eggplant)}-
        {Math.round(1.05*eggplant)})
      </div>
      <div>
        Тыквы = {melon} (
        {Math.round(0.95*melon)}-
        {Math.round(1.05*melon)})
      </div>
      <div>
        Малины = {rasbery} ({Math.round(0.95 * rasbery)}-
        {Math.round(1.05 * rasbery)})
      </div>
      <div>
        <p>Ведите Уровень домика</p>
        <input
        type="number"
        min="5" max="100"
          value={lvlHouse}
          onChange={(e) => setLvlHouse(e.target.value)}
        ></input>
      </div>
      <p>Возможных кубков = {cup} ({Math.round(cup*0.95)}-{Math.round(cup*1.10)})</p>
      <div className="result">
      <p>Наличие</p>
      <div>
      Пшеницы
      <input type="number" value={haveWeat} onChange={(e) => setHaveWeat(e.target.value)}></input>
      {Math.round((weat-haveWeat)/(12*lvlHouse))} полей,{Math.round(0.5*(weat-haveWeat)/(12*lvlHouse) )} часов
      </div>
      <div>
      Кукурузы
      <input type="number" value={haveCorn} onChange={(e) => setHaveCorn(e.target.value)}></input>
      {Math.round((corn-haveCorn)/(12*lvlHouse))} полей,{Math.round(0.66*(corn-haveCorn)/(12*lvlHouse) )} часов
      </div>
      <div>
      Помидоры
      <input type="number" value={HaveTomato} onChange={(e) => setHaveTomato(e.target.value)}></input>
      {Math.round((tomato-HaveTomato)/(12*lvlHouse))} полей,{Math.round(2*(tomato-HaveTomato)/(12*lvlHouse) )} часов
      </div>
      <div>
      Баклажаны
      <input type="number" value={HaveEggplant} onChange={(e) => setHaveEggplant(e.target.value)}></input>
      {Math.round((eggplant-HaveEggplant)/(12*lvlHouse))} полей,{Math.round(4*(eggplant-HaveEggplant)/(12*lvlHouse) )} часов
      </div>
      <div>
      Тыквы
      <input type="number" value={HaveMelon} onChange={(e) => setHaveMelon(e.target.value)}></input>
      {Math.round((melon-HaveMelon)/(12*lvlHouse))} полей,{Math.round(8*(melon-HaveMelon)/(12*lvlHouse) )} часов
      </div>
      </div>

    </>
  );
};
export default Calculation;
